import React, { useState } from "react";
import Papa from "papaparse";

function App() {
  const [error, setError] = useState(null);
  const [jsonData, setJsonData] = useState([]);
  const [apiResults, setApiResults] = useState({});
  const [selectedJson, setSelectedJson] = useState(null);
  const [copyMessage, setCopyMessage] = useState("");

  const handleFileUpload = (event) => {
    setError(null);
    setJsonData([]);
    setApiResults({});
    setSelectedJson(null);
    setCopyMessage("");
  
    const file = event.target.files[0];
    if (!file) return;
  
    Papa.parse(file, {
      header: false, // Parse without assuming headers
      skipEmptyLines: true,
      complete: function (results) {
        const data = results.data.map((row, index) => ({
          id: index,
          R: row[0], // First column
          F: parseInt(row[1], 10) || null, // Second column
          B: parseInt(row[2], 10) || null, // Third column
          D: row[3], // Fourth column
          M: row[4], // Fifth column
          N: row[5], // Sixth column
        }));
  
        // Calculate total JSON body size in bytes
        const totalJsonSize = new Blob([JSON.stringify(data)]).size;
  
        if (totalJsonSize > 65000) {
          setError("Error: Total JSON content exceeds 65KB. Please use a smaller file.");
          return;
        }
  
        setJsonData(data);
      },
    });
  };
  

  const handleApiPost = async (item) => {
    try {
      const response = await fetch("https://api.mvrassist.com/app/vehicleverify", {
        method: "POST",
        headers: {
          "Content-Type": "gen/api",
        },
        body: JSON.stringify(item),
      });

      const result = await response.json();
      setApiResults((prevResults) => ({
        ...prevResults,
        [item.id]: { status: "success", data: result },
      }));
    } catch (error) {
      setApiResults((prevResults) => ({
        ...prevResults,
        [item.id]: { status: "fail", error: error.message },
      }));
    }
  };

  const handleCopyToClipboard = () => {
    if (selectedJson) {
      navigator.clipboard.writeText(JSON.stringify(selectedJson, null, 4));
      setCopyMessage("Already copied to clipboard!");
      setTimeout(() => setCopyMessage(""), 2000); // Clear the message after 2 seconds
    }
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      {/* Left Column */}
      <div
        style={{
          width: "50%",
          overflowY: "auto",
          padding: "20px",
          borderRight: "1px solid #ccc",
        }}
      >
        <h1>CSV to JSON Converter</h1>
        <input type="file" accept=".csv" onChange={handleFileUpload} />
        {error && <p style={{ color: "red" }}>{error}</p>}
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {jsonData.map((item, index) => (
            <li
              key={item.id}
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => setSelectedJson(item)}
            >
              <span>
                <strong>Row {index}: </strong>
                {JSON.stringify(item)}
              </span>
              <button onClick={(e) => {
                e.stopPropagation(); // Prevent list item click
                handleApiPost(item);
              }}>Post</button>
            </li>
          ))}
        </ul>
      </div>

      {/* Right Column */}
      <div
        style={{
          width: "50%",
          overflowY: "auto",
          padding: "20px",
          backgroundColor: "#f4f4f4",
        }}
      >
        <h2>API Results</h2>

        {/* Selected JSON Area */}
        {selectedJson && (
          <div style={{ marginBottom: "20px" }}>
            <h3>Formatted JSON:</h3>
            <pre
              style={{
                background: "#eef",
                padding: "10px",
                borderRadius: "5px",
                overflowX: "auto",
              }}
            >
              {JSON.stringify(selectedJson, null, 4)}
            </pre>
            <button onClick={handleCopyToClipboard}>Copy to Clipboard</button>
            {copyMessage && <p style={{ color: "green" }}>{copyMessage}</p>}
          </div>
        )}

        {/* API Results */}
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {Object.entries(apiResults).map(([id, result]) => (
            <li key={id} style={{ marginBottom: "10px" }}>
              <strong>Row {id}: </strong>
              {result.status === "success"
                ? JSON.stringify(result.data)
                : `Error: ${result.error}`}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default App;
